const mockMenuDatas = [
  {
    title: 'Module 1: Introduction',
    link: 'whoAreWe',
    data: [
      {
        label: 'Who are we',
        value: 'whoAreWe',
      },
      {
        label: 'What is invoicepaid+',
        value: 'whatIsInvoicepaid',
      },
      {
        label: 'How InvoicePaid+ works',
        value: 'howInvoicePaidWorks',
      },
      {
        label: 'Using banco platform',
        value: 'usingBancoPlatform',
      },
    ],
  },
  {
    title: 'Module 2: Know your customers and their buyers',
    link: 'whoAreOurCustomers',
    data: [
      {
        label: 'Who are our customers',
        value: 'whoAreOurCustomers',
      },
      {
        label: 'Customer Success Stories',
        value: 'customerSuccessStories',
      },
      {
        label: 'Criteria for customers',
        value: 'criteriaForCustomers',
      },
      {
        label: 'Criteria for buyers',
        value: 'criteriaForBuyers',
      },
    ],
  },
  {
    title: 'Module 3: What can banco offer your customer',
    link: 'whatCanBancoOfferYourCustomer',
    data: [
      {
        label: 'What can banco offer your customer',
        highlight: '48-hour approval',
        description: `，banco swiftly provides business facilities, ensuring flexibility in
        financial pursuits.`,
        value: 'whatCanBancoOfferYourCustomer',
      },
    ],
  },
  {
    title: 'Module 4: Managing your Sales',
    link: 'howToBuildYourNetwork',
    data: [
      {
        label: 'How to build your network',
        value: 'howToBuildYourNetwork',
      },
      {
        label: 'Tracking and receiving your incentives',
        value: 'trackingAndReceivingYourIncentives',
      },
      {
        label: 'Success stories from our partners',
        value: 'successStoriesFromOurPartners',
      },
    ],
  },
];

const getMenuList = (mockMenuDatas: any) => {
  return mockMenuDatas.reduce((preResult: any, item: any) => {
    return preResult.concat(item?.data);
  }, []);
};

const menuList = getMenuList(mockMenuDatas);

export { mockMenuDatas, menuList };
