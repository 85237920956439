import { PageProps } from 'gatsby';
import React, { useEffect, useRef, useState } from 'react';
import CommonBanner from '../components/banner/CommonBanner';
import Footer from '../components/footer/Footer';
import HeaderCommon from '../components/header/HeaderCommon';
import Layout from '../components/layout';
import Seo from '../components/seo';
import 'intl-tel-input/build/css/intlTelInput.css';
import { BiChevronLeft } from 'react-icons/bi';
import { menuList, mockMenuDatas } from '../elements/baseMock';
import videojs from 'video.js';

const initialOptions: videojs.PlayerOptions = {
  controls: true,
  fluid: true,
  controlBar: {
    volumePanel: {
      inline: false,
    },
  },
};

const BancoEducation = ({ location }: PageProps) => {
  useEffect(() => {
    console.log(location.hash, 'location.hash');
    setTimeout(() => {
      window.location.hash = '';
      window.location.hash = location.hash;
    });
  }, [location.hash]);

  const [activeKey, setActiveKey] = useState<string>('whoAreWe');
  useEffect(() => {
    setActiveKey(location.hash.slice(1));
  }, [location.hash]);
  useEffect(() => {
    console.log('🧊 - BancoEducation - activeKey:', activeKey);
  }, [activeKey]);

  const rendorMenuItem = (item: any, key: number) => {
    return (
      <div className="menu-item" key={key}>
        <div className="-title">{item?.title}</div>
        <ul>
          {item?.data?.map((subItem: any, subKey: number) => {
            return (
              <li
                className={activeKey === subItem?.value ? '-active' : ''}
                key={subKey}
                onClick={() => {
                  setActiveKey(subItem?.value);
                }}
              >
                {subItem?.label}
              </li>
            );
          })}
        </ul>
      </div>
    );
  };

  const getPaginationObj = (currentKey: string) => {
    let currentIndex = 0;
    menuList?.map((item: any, key: number) => {
      if (item?.value === currentKey) {
        currentIndex = key;
      }
    });
    return {
      previous: menuList[currentIndex - 1] || {},
      next: menuList[currentIndex + 1] || {},
    };
  };
  const rendorPaginationBox = () => {
    return (
      <div className="pagination-box">
        {getPaginationObj(activeKey)?.previous?.label ? (
          <div className="btn-wrap">
            <span className="mobile-text">Previous</span>
            <span className="pc-text">&lt;</span>
            <span
              className="-btn"
              onClick={() => {
                window.scrollTo(0, 0);
                setActiveKey(getPaginationObj(activeKey)?.previous?.value);
              }}
            >
              {getPaginationObj(activeKey)?.previous?.label}
            </span>
          </div>
        ) : (
          <div></div>
        )}
        {getPaginationObj(activeKey)?.next?.label ? (
          <div className="btn-wrap">
            <span className="mobile-text">Next</span>
            <span
              className="-btn"
              onClick={() => {
                window.scrollTo(0, 0);
                setActiveKey(getPaginationObj(activeKey)?.next?.value);
              }}
            >
              {getPaginationObj(activeKey)?.next?.label}
            </span>
            <span className="pc-text">&gt;</span>
          </div>
        ) : (
          <div></div>
        )}
      </div>
    );
  };

  const rendorContent1 = () => {
    const cardItem = (src: string, label: string) => (
      <div className="-card">
        <img src={src} alt="" />
        <p>{label}</p>
      </div>
    );
    return (
      <div className="-box">
        <p className="headline">Who are we</p>
        <p className="subheading">
          Providing simple, transparent and accessible finance for growing businesses in Singapore
        </p>
        <p className="description">
          Backed by SBI holdings (Japan), SMBC,Savills, Marketnode and other strategic partners,
          banco (part of RABC Group), is a fintech company headquartered in Singapore with
          subsidiaries in China and Japan.
        </p>
        <p className="description">
          Together with its subsidiaries, the group is on the its mission of building better
          financing solutions in Asia and solving current industry challenges by providing better,
          faster, and cheaper financing.
        </p>
        {cardItem(
          '/images/education/What is banco-1.png',
          'banco platform was awarded the top winner of the Monetary Authority of Singapore’s Global FinTech Hackcelerator in 2021.',
        )}
        {cardItem(
          '/images/education/What is banco-2.png',
          'In 2022, banco entered a strategic partnership with leading real estate advisor, Savills (Singapore) Pte Ltd to digitise financing options in the property sector.',
        )}
        {cardItem(
          '/images/education/What is banco-3.png',
          'banco collaborated with IMDA Singapore on TradeTrust to provide eBL solutions, improving efficiency and reducing carbon footprint in the supply chain.',
        )}
      </div>
    );
  };

  const videoRef = useRef<HTMLVideoElement | null>(null);
  const playerRef = useRef<videojs.Player>();
  const options: videojs.PlayerOptions = {
    poster: '/images/education/sp.png',
    preload: 'metadata',
    width: 824,
    sources: [
      {
        src: '/videos/invoicepaid.mp4',
        type: 'video/mp4',
      },
    ],
  };
  useEffect(() => {
    if (!playerRef.current) {
      const videoElement = videoRef.current;
      if (!videoElement) return;
      playerRef.current = videojs(videoElement, {
        ...initialOptions,
        ...options,
      }).ready(function () {
        // console.log('onPlayerReady', this);

        const videoModalEl = document.getElementById('videoModal');
        if (videoModalEl) {
          videoModalEl.addEventListener('hidden.bs.modal', (event) => {
            this.pause();
          });
        }
      });
    }
    return () => {
      if (playerRef.current) {
        playerRef.current.dispose();
      }
    };
  }, [options]);
  const rendorContent2 = () => {
    const cardItem = (src: string, label: string, highlightDom?: any) => (
      <div className="-card">
        <img src={src} alt="" />
        {highlightDom ? highlightDom() : <p>{label}</p>}
      </div>
    );
    return (
      <div className="-box2">
        <img className="light-mode" src="/images/invoicepaid-img.png" />
        <p className="subheading">
          InvoicePaid+ is a leading invoice financing product for SMEs in Singapore
        </p>
        <div data-vjs-player>
          <video ref={videoRef} className="video-js vjs-big-play-centered" />
        </div>
        <p className="description">
          With InvoicePaid+, businesses can access up to 80% of its invoice amounts without waiting
          for customers to pay invoices. This allows businesses to tap on cash flow otherwise
          trapped in late or delayed payments. InvoicePaid+ is a cost-effective and cash
          flow-optimising option for small and medium-sized enterprises seeking a quick capital
          injection
        </p>
        <p className="description description2">
          Invoice financing is also known as ‘invoice factoring’, ‘supply chain finance’ and
          ‘accounts receivable financing
        </p>
        <p className="subheading title2">Key Features</p>
        <div className="card-wrap">
          {cardItem(
            '/images/education/What is invoicepaid+1.png',
            'Quick and Easy Online Application. Sign up in 10 mins with minimal documents to provide.',
          )}
          {cardItem(
            '/images/education/What is invoicepaid+2.png',
            'banco platform was awarded the top winner of the Monetary Authority of Singapore’s Global FinTech Hackcelerator in 2021.',
            () => (
              <p>
                Fast approval times, with offer letter issued as fast as{' '}
                <span className="-highlight">3 business hours</span> upon document submission
              </p>
            ),
          )}
        </div>
        <div className="card-wrap">
          {cardItem(
            '/images/education/What is invoicepaid+3.png',
            `One Simple All-in Platform Fee of 2%* of the invoice amount`,
          )}
          {cardItem(
            '/images/education/What is invoicepaid+4.png',
            'No Personal Guarantee required',
          )}
        </div>
      </div>
    );
  };
  const rendorContent3 = () => {
    const tipsItem = (label: string, key: number) => (
      <div className={`-tips-box tips${key}`}>
        <div className="-dot">{key}</div>
        <p className="description">{label}</p>
      </div>
    );
    return (
      <div className="-box3">
        <div className="-title-wrap">
          <p className="headline">How InvoicePaid+ works</p>
        </div>
        <div className="img-box">
          <img src="/images/education/How InvoicePaid+ works.png" alt="" />
          {tipsItem('Company send invoice or PO to customer', 1)}
          {tipsItem('Company finances invoices to banco via InvoicePaid+', 2)}
          {tipsItem('Company receives 70%-80% of invoice in cash advance，minus a fee', 3)}
          {tipsItem('Customer makes full payment to banco', 4)}
          {tipsItem(
            'After the customer pays full invoice amount to banco,the company receives remaining amount without any additional fees',
            5,
          )}
        </div>
        <div className="hiden-video" />
        <div className="-tips-list">
          <div>
            <div className="-dot">1</div>
            <p className="description">Company send invoice or PO to customer</p>
          </div>
          <div>
            <div className="-dot">2</div>
            <p className="description">Company finances invoices to banco via InvoicePaid+</p>
          </div>
          <div>
            <div className="-dot">3</div>
            <p className="description">
              Company receives 70%-80% of invoice in cash advance，minus a fee
            </p>
          </div>
          <div>
            <div className="-dot">4</div>
            <p className="description">Customer makes full payment to banco</p>
          </div>
          <div>
            <div className="-dot">5</div>
            <p className="description">
              After the customer pays full invoice amount to banco,the company receives remaining
              amount without any additional fees
            </p>
          </div>
        </div>
      </div>
    );
  };

  const rendorContent4 = () => {
    const stepItem = ({ label, description, descriptionHighlight, steps }: any) => (
      <div className="-step">
        <span className="hightlight-tips">{label}</span>
        {descriptionHighlight ? (
          descriptionHighlight()
        ) : (
          <p className="description">{description}</p>
        )}
        {steps?.length > 0 && (
          <div className="step-ul">
            {steps?.map((item: any, key: number) => {
              return (
                <div className="step-li" key={key}>
                  <img src="/images/education/check.png" alt="" />
                  <span>{item}</span>
                </div>
              );
            })}
          </div>
        )}
      </div>
    );
    return (
      <div className="-box4">
        <p className="headline">Using banco platform</p>
        <p className="subheading">
          Signing up on the banco platform is done 100% digitally, where your assigned referrals can
          seamlessly receive your invitation, register an account and receive approvals with ease on
          our digital platform.
        </p>
        <p className="-title">Your customer will：</p>

        <div className="box-card card1">
          {stepItem({
            label: 'STEP 1',
            description: 'Receive an invitation from you to sign up on banco platform',
          })}
          <div className="-img-wrap">
            <img className="img-base" src="/images/education/How banco works-step1.png" alt="" />
            <img className="img-tips-1" src="/images/education/step1-1.png" alt="" />
            <img className="img-tips-2" src="/images/education/step1-2.png" alt="" />
          </div>
        </div>
        <div className="box-card card2">
          <div className="-img-wrap">
            <img className="img-base" src="/images/education/How banco works-step2.png" alt="" />
            <img className="img-tips-1" src="/images/education/step2-1.png" alt="" />
          </div>
          {stepItem({
            label: 'STEP 2',
            description: 'Register an account via our sign-up page',
            steps: ['Basic information', 'OTP verification', 'Set password', 'Company information'],
          })}
        </div>
        <div className="box-card card3">
          {stepItem({
            label: 'STEP 3',
            descriptionHighlight: () => (
              <p className="description">
                Complete their <span className="-highlight">KYC</span> using their corppass in less
                than 5 mins
              </p>
            ),
          })}
          <div className="-img-wrap">
            <img className="img-base" src="/images/education/How banco works-step3.png" alt="" />
            <img className="img-tips-1" src="/images/education/step3-1.png" alt="" />
          </div>
        </div>
        <div className="box-card card4">
          <div className="-img-wrap">
            <img className="img-base" src="/images/education/How banco works-step4.png" alt="" />
            <img className="img-tips-1" src="/images/education/step4-1.png" alt="" />
          </div>
          {stepItem({
            label: 'STEP 4',
            description: 'Submit buyers information and supporting documents',
            steps: ['Add your buyer', 'Upload your bank statements'],
          })}
        </div>
        <div className="box-card card5">
          {stepItem({
            label: 'STEP 5',
            description: 'Once approved, submit their invoices for financing',
          })}
          <div className="-img-wrap">
            <img className="img-base" src="/images/education/How banco works-step5.png" alt="" />
            <img className="img-tips-1" src="/images/education/step5-1.png" alt="" />
            <img className="img-tips-2" src="/images/education/step5-2.png" alt="" />
          </div>
        </div>
        <p className="description">
          Thereafter, they will be able to start accessing our financing services, InvoicePaid+ or
          EquipPaid+ to grow their business with simple, transparent and accessible financing
        </p>
        <p className="description">
          In Singapore, more than 60% of SMEs lack sufficient funds to sustain their operations for
          more than six months.
        </p>
        <p className="-title title2">
          <span>
            Therefore at banco, our mission is to address this cash flow dilemma. In December 2022,{' '}
          </span>
          <span className="-highlight">
            we launched the #SMEMakeEndsMeet initiative, aimed at assisting Singaporean SMEs in
            unlocking $100 million in cash.
          </span>
        </p>
      </div>
    );
  };

  const dotItem = (label: string, key: number) => (
    <div className="-dot-box" key={key}>
      <div className="-dot" />
      <span className="-label">{label}</span>
    </div>
  );
  const rendorContent5 = () => {
    const customersList = [
      'Cleaning',
      'Manpower Supply',
      'Training and development',
      'Security',
      'Digital Platforms',
      'Business outsourcing',
      'Landscaping',
      'Professional services',
      'Logistics',
      'And more',
    ];
    return (
      <div className="-box5">
        <p className="headline">Who are our customers</p>
        <p className="description">
          Typically our customers are B2B companies that provide products and services to variety of
          industries.
        </p>
        <div className="-box-card">
          {customersList?.map((item: any, key: number) => dotItem(item, key))}
        </div>
        <p className="description">
          They typically engage in recurring sales with both new and existing customers regularly,
          whether it be weekly, monthly, or quarterly. Often, they are rapidly expanding and
          actively seeking new buyers or suppliers to facilitate their trade operations.
        </p>
        <p className="description">
          They normally suffer from long payment terms (Up to 150 days) as they do not have the
          negotiation power or have to offer more favourable payment terms in order to win the deals
          with their larger customers. Resulting in a cash flow gap which they have to fill using
          their limited cash reserves or through bank loans. The bigger they grow, the bigger their
          cashflow gap.
        </p>
        <p className="description">
          They have insufficient cash reserves, and their credit limits with banks often fall short
          of their requirements. Additionally, traditional banks tend to have lengthy
          decision-making processes when it comes to loan applications. Consequently, many of these
          companies face a shortage of funds, hindering their ability to grow at the desired pace.
        </p>
      </div>
    );
  };

  const rendorContent6 = () => (
    <div className="-box6">
      <p className="headline">Customer Success Stories</p>
      <span className="hightlight-tips">Cleaning Company in Singapore</span>
      <p className="-title">Investing into the future</p>
      <p className="description">
        Without borrowing more money, company was able to free up sufficient cash (500K) to recruit
        new personnel and invest in productivity system to ensure that the company remains
        competitive in the market going into the future.
      </p>
      <div className="img-box">
        <img src="/images/education/Customer Success Stories-1.png" alt="" />
      </div>

      <span className="hightlight-tips">Security Company in Singapore</span>
      <p className="-title">For working capital requirements & growth</p>
      <p className="description">
        With invoicepaid+, customer was able to have cash to not just cover its existing working
        capital requirement but also have cash to support another 3 new projects.
      </p>
      <div className="img-box">
        <img src="/images/education/Customer Success Stories-2.png" alt="" />
      </div>
    </div>
  );

  const customersItem = ({ label, labelHighlight, subItem }: any) => (
    <div className="customers-item">
      <div className="dot-wrap">
        <div className="highlight-dot" />
        {labelHighlight ? labelHighlight() : <span className="-title">{label}</span>}
      </div>
      {subItem?.length > 0 && (
        <div className="sub-item-wrap">
          {subItem?.map((item: any, key: number) => {
            return (
              <p className="sub-item" key={key}>
                {item}
              </p>
            );
          })}
        </div>
      )}
    </div>
  );

  const rendorContent7 = () => {
    return (
      <div className="-box7">
        <p className="headline">Criteria for customers</p>
        <p className="subheading">
          Although many companies need cash,not all of them can become banco clients. We have
          certain criteria that clients must satisfy in order to be funded by us
        </p>
        <div className="box-card">
          {customersItem({
            labelHighlight: () => (
              <span className="-title">
                They must have been a legal entity and in operation for at{' '}
                <span className="-highlight">least 6 months</span>
              </span>
            ),
          })}
          {customersItem({
            labelHighlight: () => (
              <span className="-title">
                They must be providing goods and services in a{' '}
                <span className="-highlight">B2B space</span>
              </span>
            ),
          })}
          {customersItem({
            label: 'Engage in either domestic and international trades',
          })}
          {customersItem({
            label: 'They cannot be',
            subItem: ['a. Insolvent entity', 'b. In court', 'c. Or affiliated to the buyer'],
          })}
        </div>
        <p className="description">
          banco will provide the necessary funds for invoices prior to their payment deadline. In
          this arrangement, banco will receives repayment from the Buyer at a later time.
        </p>
        <p className="description">
          Hence, it is essential to evaluate the creditworthiness of the Buyer to determine the
          funding limit. The Supplier can enhance this limit by providing additional details about
          their Buyer.
        </p>
      </div>
    );
  };

  const rendorContent8 = () => {
    const customersList = [
      'Hospitality',
      'Environmental',
      'Schools',
      'Property',
      'F&B',
      'Healthcare',
      'Events',
      'Manufacturing',
      'Marine',
      'Construction',
      'Retail',
      'IT',
      'Tourism',
    ];
    return (
      <div className="-box8">
        <p className="headline">Criteria for buyers</p>
        <p className="subheading">
          The ideal buyers should demonstrate financial stability, a strong credit profile, and a
          consistent track record of prompt payment. These factors reduce the risk for banco as the
          financing provider and increase the likelihood of a successful and mutually beneficial
          financing arrangement.
        </p>
        <p className="description">Typically, the buyers would be from these sectors</p>
        <div className="-box-card">
          {customersList?.map((item: any, key: number) => dotItem(item, key))}
        </div>
        <p className="-title">Buyers cannot be:</p>
        <div className="-box-flex">
          <div className="flex-item">
            <img src="/images/education/Criteria for buyers-1.png" alt="" />
            <p>Insolvent entity</p>
          </div>
          <div className="flex-item">
            <img src="/images/education/Criteria for buyers-2.png" alt="" />
            <p>A company affiliated to the Buyer</p>
          </div>
          <div className="flex-item">
            <img src="/images/education/Criteria for buyers-3.png" alt="" />
            <p>In court</p>
          </div>
        </div>
        <p className="description">
          Above all, the ideal buyer should exhibit a favorable credit status by showcasing
          profitable trading, positive equity, and a reasonable account balance. Regrettably, we are
          unable to provide support to businesses that have filed for bankruptcy or are currently
          undergoing the bankruptcy filing process within the last two years.
        </p>
        <p className="description">
          banco strives to facilitate a straightforward, transparent, and fair financing process for
          buyers. Credit reports from established credit bureaus like Dun and Bradstreet, Singapore
          commercial credit bureau, Experian and more, would facilitate the approval process.
        </p>
      </div>
    );
  };

  const rendorContent9 = () => (
    <div className="-box9">
      <p className="headline">What can banco offer your customer</p>
      <p className="subheading">
        Within <span className="-highlight">48 hours</span> of an approved application, banco can
        provide businesses with a facility they will normally receive months later from their
        Buyers. This is an ideal solution to a common problem, and it allows companies to pursue
        their financial goal with more flexibiity.
      </p>
      <p className="description">Benefits:</p>
      <div className="-box-flex">
        <div className="flex-item">
          <img src="/images/education/What can banco offer your customer-1.png" alt="" />
          <p>Access the funds they need to grow their business</p>
        </div>
        <div className="flex-item">
          <img src="/images/education/What can banco offer your customer-2.png" alt="" />
          <p>Improve their cash flow and reduce financial stress</p>
        </div>
        <div className="flex-item">
          <img src="/images/education/What can banco offer your customer-3.png" alt="" />
          <p>Get paid faster, without having to wait for their customers to pay their invoices</p>
        </div>
        <div className="flex-item">
          <img src="/images/education/What can banco offer your customer-4.png" alt="" />
          <p>Maintain control over their business finances</p>
        </div>
        <div className="flex-item">
          <img src="/images/education/What can banco offer your customer-5.png" alt="" />
          <p>Increase their buying power and take advantage of opportunities as they arise</p>
        </div>
      </div>
    </div>
  );

  const rendorContent10 = () => {
    const desArrCard = (desArr: Array<string>) => (
      <div className="description-card">
        {desArr?.length > 0 &&
          desArr?.map((item: any, key: number) => {
            return (
              <div className="description-item" key={key}>
                <p className="-text">{item}</p>
              </div>
            );
          })}
      </div>
    );
    return (
      <div className="-box10">
        <p className="headline">How to build your network</p>
        <p className="subheading">
          By signing up as our banco partner (BP) would means that you would have an established
          network in one or more industries which you could easily leverage to kick start your
          referral business with banco.
        </p>
        <p className="description">You can start with your existing network by;</p>
        <div className="box-card">
          {customersItem({
            label: 'Identify potential companies',
            subItem: [
              `Clearly define the ideal individuals or businesses who would benefit from services. Are your contacts talking to you about an upcoming business expansion? Are they facing any short term cash flow situations from unforeseen expenses? Finally, knowing the profile of their business and their buyers will help you identify the correct referrals
            to use banco services.`,
            ],
          })}
          {customersItem({
            label: 'Understand the challenges and requirements faced by their company',
            subItem: [
              `a. Long payment terms from their buyers (>30 days)`,
              `b. Difficulty to collect. High Account Receivables (AR)`,
              `c. Tight cashflow. Struggle to make monthly business expenditures`,
              `d. Growing but unable to take on new projects`,
            ],
          })}
        </div>
        <p className="description">
          Otherwise, here are some ways by our successful partners to build their network
        </p>
        <div className="box-card">
          {customersItem({
            label: 'Utilize social media platforms',
            subItem: [
              `Tap into the power of social media to expand your reach and connect with potential clients. Potential clients can be sourced from web forums, professional platforms, messenger groups and social media channels like LinkedIn, Facebook or TikTok.`,
            ],
          })}
          {customersItem({
            label: 'Attend networking events',
            subItem: [
              `Attend industry conferences, trade shows, and local networking events to meet new people and establish meaningful connections. Be proactive in sharing about banco and collecting contact information for future follow-ups.`,
            ],
          })}
        </div>
        <p className="subheading subheading2">Asking the right question</p>
        <p className="-title">
          To determine if your network members qualify for invoice financing, you can ask them the
          following qualifying questions:
        </p>
        <p className="description">Qualify if they have any need for invoice financing</p>
        {desArrCard([
          '"Do you have outstanding invoices from large customers that are due for payment?"',
          '"Are you facing cash flow challenges due to delayed customer payments or long payment cycles?"',
          '"Do you have a minimum invoice value that you typically work with or a specific threshold for invoice financing?"',
          '"Do you have a track record of consistent sales and a proven history of delivering goods or services to your customers?"',
          '"Are you having problems making business expenses like salary payments because of delayed payments from customers?"',
        ])}
        <p className="-title">
          If he/she qualifies for the above, we can delve more into their openness with a financier
          like banco
        </p>
        {desArrCard([
          '"Are you open to sharing some financial information or providing documentation related to your business, such as financial statements or accounts receivable aging reports?',
          '"Are you comfortable with a third-party provider managing the collection of your outstanding invoices?"',
          '"Are you aware of the costs associated with invoice financing, such as discount rates or service fees, and do you have a budget that can accommodate these costs?"',
          '"Are you committed to maintaining strong relationships with your customers, even if their payments are managed by an invoice financing provider?"',
        ])}
        <p className="-title">
          Clearly communicate banco’s value proposition to them. Highlight the key benefits by
          taking up this service with banco versus traditional financiers like banks.
        </p>
        <div className="-box-card">
          <div className="-item">
            <img src="/images/education/How to build your network-1.png" alt="" />
            <p>FAST APPLICATION AND APPROVAL</p>
          </div>
          <div className="-item">
            <img src="/images/education/How to build your network-2.png" alt="" />
            <p>ONE SINGLE FEE. NO HIDDEN CHARGES</p>
          </div>
          <div className="-item">
            <img src="/images/education/How to build your network-3.png" alt="" />
            <p>DISBURSEMENT IN 48 HOURS</p>
          </div>
        </div>
      </div>
    );
  };

  const rendorContent11 = () => (
    <div className="-box11">
      <p className="headline">Tracking and receiving your incentives</p>
      <p className="subheading">
        Simple. Your incentives will be <span className="-highlight">10%</span> of the fee that
        banco receives. When you invite your first client, they will register and upload their first
        invoice for financing.
      </p>
      <div className="img-wrap">
        <img src="/images/education/Tracking and receiving your incentives-1.png" alt="" />
      </div>
      <p className="description">
        We then check the client's transaction and finance its invoice. At this point, we will sign
        an agreement with you and ask you for bank details to which your incentive payments can be
        made.
      </p>
      <p className="description">
        You can track the progress of the invoices on the partners’ platform. We will transfer the
        incentives to your stipulated bank account for all invoices paid by your customers in the
        month within 7 business days after month ends.
      </p>
      <p className="sub-description">
        *Incentives will be payable for the first 12 months of invoices only, starting from the
        submission date of the first invoice.
      </p>
    </div>
  );

  const rendorContent12 = () => (
    <div className="-box12">
      <p className="headline">Success stories from our partners</p>
      <div className="img-wrap">
        <img src="/images/education/Success stories from our partners-1.png" alt="" />
      </div>
      <p className="-title">
        Meet Mr. Lim, a Logistics Manager employed at a prominent logistics operator that caters to
        small and medium-sized companies in Singapore. In the past year, he earned remarkable
        incentives amounting to <span className="-highlight">$10,800</span> by working part-time.
      </p>
      <p className="description">
        Last year, Mr. Lim made the strategic decision to become a banco Partner with the aim of
        assisting his clients in securing turnover financing. Leveraging his extensive network, he
        successfully introduced <span className="-highlight">six clients</span> to the banco
        financial platform.
      </p>
      <p className="description">
        Thanks to his efforts, these clients now have access to a substantial capital of
        approximately <span className="-highlight">$5,400,000</span> provided by banco. Mr. Wong's
        dedication and partnership with banco have brought immense value to his clients' financial
        needs.
      </p>
      <div className="img-wrap">
        <img src="/images/education/Success stories from our partners-2.png" alt="" />
      </div>
      <p className="-title">
        Allow us to introduce Miss Yeo, a determined accountant who, in light of the COVID-19
        impact, sought new avenues to expand her network and provide additional support to her
        valued clients. Recognizing the potential, she made the decision to become a banco Partner.
      </p>
      <p className="description">
        Through her efforts, Miss Yeo successfully introduced{' '}
        <span className="-highlight">seven clients</span> from her extensive network to the banco
        financial platform. These clients, eager to secure financing for their turnovers, accessed a
        substantial capital of approximately <span className="-highlight">$4,950,000</span> offered
        by banco.
      </p>
      <p className="description">
        Thanks to Miss Yeo's commitment and the financial assistance provided by banco, Miss Pham
        earned impressive incentives totaling <span className="-highlight">$9,500</span> over the
        past year
      </p>
      <p className="description">
        Miss Yeo's determination, combined with the partnership between her clients and banco,
        exemplifies the positive impact that can be achieved through collaboration and innovative
        financial solutions.
      </p>
    </div>
  );

  const rendorContent = (key: string) => {
    let dom: any;
    switch (key) {
      case 'whoAreWe':
        dom = rendorContent1();
        break;
      case 'whatIsInvoicepaid':
        dom = rendorContent2();
        break;
      case 'howInvoicePaidWorks':
        dom = rendorContent3();
        break;
      case 'usingBancoPlatform':
        dom = rendorContent4();
        break;
      case 'whoAreOurCustomers':
        dom = rendorContent5();
        break;
      case 'customerSuccessStories':
        dom = rendorContent6();
        break;
      case 'criteriaForCustomers':
        dom = rendorContent7();
        break;
      case 'criteriaForBuyers':
        dom = rendorContent8();
        break;
      case 'whatCanBancoOfferYourCustomer':
        dom = rendorContent9();
        break;
      case 'howToBuildYourNetwork':
        dom = rendorContent10();
        break;
      case 'trackingAndReceivingYourIncentives':
        dom = rendorContent11();
        break;
      case 'successStoriesFromOurPartners':
        dom = rendorContent12();
        break;

      default:
        dom = rendorContent1();
        break;
    }
    return dom;
  };

  return (
    <Layout>
      <Seo
        title="About banco| Financing platform for growing businesses in Asia"
        description="banco is a financing platform for growing businesses in Asia. Unlock opportunities in supply chain to empower companies control cash flow and scale business"
      />
      <main className="main-wrapper"> 
        <div className="education-header-img">
          <img src="/images/education/banner.png" alt="" />
        </div>
        <CommonBanner>
          <HeaderCommon />
          <div className="education-header-title">Education</div>
          <a className="back-link" href="/education">
            <BiChevronLeft />
            back to education
          </a>
          <div className="education-content-wrap">
            <div className="left-menu">
              {mockMenuDatas?.map((item: any, key: number) => {
                return rendorMenuItem(item, key);
              })}
            </div>
            <div className="content">
              {rendorContent(activeKey)}
              {/* 底部翻页 */}
              {rendorPaginationBox()}
            </div>
          </div>
        </CommonBanner>

        <Footer />
      </main>
    </Layout>
  );
};

export default BancoEducation;
